import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  Dropdown,
  Spinner,
  ToastContainer,
  Toast,
  Modal,
} from "react-bootstrap";
import { useTable, usePagination, Column, CellProps } from "react-table";
import PageTitle from "../../components/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface Company {
  id: number;
  company_name: string;
  company_email: string;
  company_phone: string;
  address: string;
  logo: string;
}

const Companies: React.FC = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<string>("");
  const [companyData, setCompanyData] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const [companyName, setCompanyName] = useState("");
  const [appName, setAppName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [logo, setLogo] = useState<File | null>(null);
  // Fetch companies from the API
  useEffect(() => {
    setLoading(true);
    fetch("https://superdolphins2024.onrender.com/api/v1/companies/get-all")
      .then((response) => response.json())
      .then((data) => {
        setCompanyData(data.data || []);
      })
      .catch((error) => {
        console.error("Error fetching companies data:", error);
        setToastMessage("Failed to fetch companies.");
        setToastVariant("danger");
        setShowToast(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  console.log(companyData);

  const handleEditClick = (id: number) => {
    navigate(`edit-company/${id}`);
  };

  const handleDelete = async (id: number) => {
    try {
      if (!id || typeof id !== "number") {
        setToastMessage("Invalid company ID provided.");
        setToastVariant("danger");
        setShowToast(true);
        return;
      }

      const apiUrl = `https://superdolphins2024.onrender.com/api/v1/companies/delete-company/${id}`;
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error(`Failed to delete company: ${errorMessage}`);
        setToastMessage(`Failed to delete company: ${errorMessage}`);
        setToastVariant("danger");
        setShowToast(true);
        return;
      }

      Swal.fire({
        title: "Company Deleted!",
        icon: "success",
      });

      setCompanyData((prevData) =>
        prevData.filter((company) => company.id !== id)
      );

      setToastMessage(`Company with ID ${id} deleted successfully.`);
      setToastVariant("success");
      setShowToast(true);
    } catch (error) {
      console.error("An error occurred while deleting the company:", error);
      setToastMessage("An error occurred while deleting the company.");
      setToastVariant("danger");
      setShowToast(true);
    }
  };

  const handleViewCompany = (id: number) => {
    // try {
    setLoading(true);

    if (id) {
      setLoading(false);
      setShowModal(true);
      fetch(`https://superdolphins2024.onrender.com/api/v1/companies/get/${id}`)
        .then((response) => response.json())
        .then((data) => {
          const company = data.data.company;
          setCompanyName(company.company_name);
          setAppName(company.app_name || "");
          setCompanyEmail(company.company_email);
          setCompanyPhone(company.company_phone);
          setSelectedCompany(company);
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    }
  };

//   const filteredData = React.useMemo(
//     () =>
//       (companyData || []).filter((row) =>
//         row.company_name.toLowerCase().includes(filter.toLowerCase())
//       ),
//     [companyData, filter]
//   );

const filteredData = React.useMemo(
    () =>
      (companyData || []).filter((row) =>
        row.company_name && row.company_name.toLowerCase().includes(filter.toLowerCase())
      ),
    [companyData, filter]
  );

  const columns: Column<Company>[] = React.useMemo(
    () => [
      { Header: "Company ID", accessor: "id" },
      { Header: "Company Name", accessor: "company_name" },
      { Header: "Email", accessor: "company_email" },
      { Header: "Phone", accessor: "company_phone" },
      { Header: "Address", accessor: "address" },
      {
        Header: "Logo",
        accessor: "logo",
        Cell: ({ row }: CellProps<Company>) => (
          <a href={row.original.logo} target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="sm">
              View
            </Button>
          </a>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }: CellProps<Company>) => (
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" size="sm">
              <i className="bi bi-three-dots-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleViewCompany(row.original.id)}>
                View
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleEditClick(row.original.id)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDelete(row.original.id)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<Company>(
    { columns, data: filteredData, initialState: { pageIndex: 0 } },
    usePagination
  );

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCompany ? (
            <div className="row">
              {/* First Column */}
              <div className="col-lg-6 mb-3">
                <p>
                  <strong>ID:</strong> {selectedCompany.id}
                </p>
                <p>
                  <strong>Name:</strong> {selectedCompany.company_name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedCompany.company_email}
                </p>
                <p>
                  <strong>Phone:</strong> {selectedCompany.company_phone}
                </p>
                <p>
                  <strong>Address:</strong> {selectedCompany.address}
                </p>

                <p>
                  <strong>Logo:</strong>{" "}
                  <a
                    href={selectedCompany.logo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Logo
                  </a>
                </p>
              </div>
            </div>
          ) : (
            <p>Loading company details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mb-4">
        <PageTitle
          breadCrumbItems={[
            { label: "Companies", path: "/hr/companies", active: true },
          ]}
          title="Companies"
        />
        <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
          <Form.Group controlId="search" className="me-2">
            <Form.Label>Search:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Search by company name"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Form.Group>
          <Link to="/companies/create">
            <Button variant="primary">Add Company</Button>
          </Link>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center mt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Table {...getTableProps()} striped bordered hover className="mt-3">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <Button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              variant="outline-secondary"
            >
              {"<<"}
            </Button>{" "}
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              variant="outline-secondary"
            >
              {"<"}
            </Button>{" "}
            <Button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              variant="outline-secondary"
            >
              {">"}
            </Button>{" "}
            <Button
              onClick={() => gotoPage(pageOptions.length - 1)}
              disabled={!canNextPage}
              variant="outline-secondary"
            >
              {">>"}
            </Button>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <div>
              <Form.Select
                value={pageSize}
                onChange={(e) =>
                  setPageSize(Number((e.target as HTMLSelectElement).value))
                }
                size="sm"
              >
                {[10, 25, 50, 100].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companies;
