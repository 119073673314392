// import React, { useState } from "react";
// import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
// import DIImg from "../../../assets/images/diImg.png";
// import Footer from "../../../layouts/Footer";

// interface Applicant {
//   name: string;
//   email: string;
//   phone_no?: string;
//   address?: string;
//   department?: string;
//   total_exp?: string;
//   current_ctc?: string;
//   expected_ctc?: string;
//   ctc_rate?: string;
//   cv?: File | null;
//   cover_letter?: string;
//   portfolio?: File | null;
//   laptop_desktop: string;
//   timing: string;
//   language: string;
//   freelancer_working: string;
//   overtime: string;
// }

// const ApplicantForm: React.FC = () => {
//   const [applicant, setApplicant] = useState<Applicant>({
//     name: "",
//     email: "",
//     phone_no: "",
//     address: "",
//     department: "",
//     total_exp: "",
//     current_ctc: "",
//     expected_ctc: "",
//     ctc_rate: "",
//     cv: null,
//     cover_letter: "",
//     portfolio: null,
//     laptop_desktop: "",
//     timing: "",
//     language: "",
//     freelancer_working: "",
//     overtime: "",
//   });

//   const [loading, setLoading] = useState<boolean>(false); // Loading state

//   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setApplicant({
//       ...applicant,
//       [name]: value,
//     });
//   };

//   const handleChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     const { name, value } = e.target;
//     setApplicant({
//       ...applicant,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, files } = e.target;
//     if (files && files[0]) {
//       setApplicant({
//         ...applicant,
//         [name]: files[0],
//       });
//     }
//   };

//   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     setLoading(true); // Set loading to true

//     const formData = new FormData();
//     for (const key in applicant) {
//       const value = applicant[key as keyof Applicant];
//       if (value !== null && value !== undefined) {
//         formData.append(key, value);
//       }
//     }

//     try {
//       const response = await fetch(
//         "https://superdolphins2024.onrender.com/api/v1/hr/create-applicant",
//         {
//           method: "POST",
//           body: formData, // Use formData instead of JSON.stringify
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log(result);
//       // Handle success (e.g., show a success message)

//       // Reset the form
//       setApplicant({
//         name: "",
//         email: "",
//         phone_no: "",
//         address: "",
//         department: "",
//         total_exp: "",
//         current_ctc: "",
//         expected_ctc: "",
//         ctc_rate: "",
//         cv: null,
//         cover_letter: "",
//         portfolio: null,
//         laptop_desktop: "",
//         timing: "",
//         language: "",
//         freelancer_working: "",
//         overtime: "",
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       // Handle error (e.g., show an error message)
//     } finally {
//       setLoading(false); // Reset loading state
//     }
//   };

//   return (
//     <>
//       <Container>
//         <Card style={{ width: "100%", paddingBottom: "5px" }}>
//           <Card.Img
//             variant="top"
//             src="https://images.pexels.com/photos/8235881/pexels-photo-8235881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//             style={{ height: "220px", objectFit: "cover" }}
//           />
//           <Card.Body>
//             <Card.Img
//               src={DIImg}
//               style={{
//                 height: "100px",
//                 width: "100px",
//                 objectFit: "cover",
//                 position: "absolute",
//                 top: "45%",
//                 border: "1px solid #fff",
//               }}
//             />
//             <Card.Title style={{ marginTop: "70px", fontSize: "1.6rem" }}>
//               Applicants Form
//             </Card.Title>
//           </Card.Body>
//         </Card>

//         <Card style={{ padding: "20px 10px" }}>
//           <Card.Body>
//             <form onSubmit={handleSubmit}>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formName" className="mb-3">
//                     <Form.Label
//                       style={{ fontSize: "1.1rem", fontWeight: "bold" }}
//                     >
//                       Name
//                     </Form.Label>{" "}
//                     <span style={{ color: "red" }}>*</span>
//                     <Form.Control
//                       type="text"
//                       name="name"
//                       value={applicant.name}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         fontSize: "1rem",
//                         padding: "13px",
//                         borderRadius: "10px",
//                         border: "2px solid #ddd",
//                         backgroundColor: "#f8f9fa",
//                         transition: "all 0.3s",
//                       }}
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group controlId="formEmail" className="mb-3">
//                     <Form.Label>Email</Form.Label>
//                     <Form.Control
//                       type="email"
//                       name="email"
//                       value={applicant.email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formPhoneNo" className="mb-3">
//                     <Form.Label>Phone Number</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="phone_no"
//                       value={applicant.phone_no}
//                       onChange={handleChange}
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group controlId="formAddress" className="mb-3">
//                     <Form.Label>Address</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="address"
//                       value={applicant.address}
//                       onChange={handleChange}
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>Total Experience</Form.Label>
//                     <Form.Select
//                       name="total_exp"
//                       value={applicant.total_exp}
//                       onChange={handleSelectChange}
//                     >
//                       <option value="fresher">Fresher</option>
//                       <option value="1-2">1-2</option>
//                       <option value="2-3">2-3</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group controlId="formCTC" className="mb-3">
//                     <Form.Label>Current CTC</Form.Label>
//                     <Form.Control
//                       type="number"
//                       name="current_ctc"
//                       value={applicant.current_ctc}
//                       onChange={handleChange}
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formCTCRate" className="mb-3">
//                     <Form.Label>Current CTC Rate</Form.Label>
//                     <Form.Select
//                       name="ctc_rate"
//                       value={applicant.ctc_rate}
//                       onChange={handleSelectChange}
//                     >
//                       <option value="per hour">per Hour</option>
//                       <option value="per month">per Month</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group controlId="formExpectedCTC" className="mb-3">
//                     <Form.Label>Expected CTC</Form.Label>
//                     <Form.Control
//                       type="number"
//                       name="expected_ctc"
//                       value={applicant.expected_ctc}
//                       onChange={handleChange}
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>Department</Form.Label>
//                     <Form.Select
//                       name="department"
//                       value={applicant.department}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="Graphics">Graphics</option>
//                       <option value="Web">Web</option>
//                       <option value="Content">Content</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group controlId="formCv" className="mb-3">
//                     <Form.Label>CV</Form.Label>
//                     <Form.Control
//                       type="file"
//                       name="cv"
//                       onChange={handleFileChange}
//                       required
//                     />
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formCoverLetter" className="mb-3">
//                     <Form.Label>Cover Letter</Form.Label>
//                     <Form.Control
//                       as="textarea"
//                       rows={3}
//                       name="cover_letter"
//                       onChange={handleChange}
//                       value={applicant.cover_letter}
//                     />
//                   </Form.Group>
//                 </Col>
//                 {applicant.department === "Graphics" && (
//                   <Col md={6}>
//                     <Form.Group controlId="formPortfolio" className="mb-3">
//                       <Form.Label>Portfolio</Form.Label>
//                       <Form.Control
//                         type="file"
//                         name="portfolio"
//                         onChange={handleFileChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 )}
//               </Row>

//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>Do you have laptop/Desktop?</Form.Label>
//                     <Form.Select
//                       name="laptop_desktop"
//                       value={applicant.laptop_desktop}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>

//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>Work timing</Form.Label>
//                     <Form.Select
//                       name="timing"
//                       value={applicant.timing}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="full-time">Full Time (9AM to 6PM)</option>
//                       <option value="part-time">
//                         Part Time (9AM to 2PM/2AM to 6PM)
//                       </option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>
//               </Row>

//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>
//                       Are you freelancer or working somewhere else?
//                     </Form.Label>
//                     <Form.Select
//                       name="freelancer_working"
//                       value={applicant.freelancer_working}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>

//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>
//                       Are you willing to work overtime when necessary?
//                     </Form.Label>
//                     <Form.Select
//                       name="overtime"
//                       value={applicant.overtime}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>
//               </Row>

//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>
//                       Are you freelancer or working somewhere else?
//                     </Form.Label>
//                     <Form.Select
//                       name="freelancer_working"
//                       value={applicant.freelancer_working}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>

//                 <Col md={6}>
//                   <Form.Group controlId="formDepartment" className="mb-3">
//                     <Form.Label>
//                       Are you willing to work overtime when necessary?
//                     </Form.Label>
//                     <Form.Select
//                       name="overtime"
//                       value={applicant.overtime}
//                       onChange={handleSelectChange}
//                     >
//                       <option>Open this select menu</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Form.Select>
//                   </Form.Group>
//                 </Col>
//               </Row>

//               <hr />

//               <Card.Title style={{ marginTop: "40px", fontSize: "1.4rem" }}>
//                 Terms And Condition
//               </Card.Title>

//               <Card.Text
//                 style={{
//                   marginTop: "30px",
//                   fontSize: "1rem",
//                   lineHeight: "1.7",
//                 }}
//               >
//                 {/* Your terms and conditions text here */}
//               </Card.Text>

//               <Form.Check
//                 inline
//                 label="I Agree with the above Terms and conditions"
//                 name="group1"
//               />

//               <hr />

//               <div className="mt-4 d-flex gap-2">
//                 <Button variant="primary" type="submit" disabled={loading}>
//                   {loading ? "Submitting..." : "Apply"}
//                 </Button>
//                 <Button variant="danger">Cancel</Button>
//               </div>
//             </form>
//           </Card.Body>
//         </Card>
//       </Container>
//       <div className="mt-5">
//         <Footer />
//       </div>
//     </>
//   );
// };

// export default ApplicantForm;

import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import DIImg from "../../../assets/images/diImg.png";
import Footer from "../../../layouts/Footer";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import Swal from "sweetalert2";

const languageOptions = [
  { value: "english", label: "English" },
  { value: "hindi", label: "Hindi" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
];

interface Applicant {
  name: string;
  email: string;
  phone_no?: string;
  address?: string;
  department?: string;
  total_exp?: string;
  current_ctc?: string;
  expected_ctc?: string;
  ctc_rate?: string;
  cv?: File | null;
  cover_letter?: string;
  portfolio?: File | null;
  laptop_desktop: string;
  timing: string;
  language: string[];
  freelancer_working: string;
  overtime: string;
}

const ApplicantForm: React.FC = () => {
  const [applicant, setApplicant] = useState<Applicant>({
    name: "",
    email: "",
    phone_no: "",
    address: "",
    department: "",
    total_exp: "",
    current_ctc: "",
    expected_ctc: "",
    ctc_rate: "",
    cv: null,
    cover_letter: "",
    portfolio: null,
    laptop_desktop: "",
    timing: "",
    language: [],
    freelancer_working: "",
    overtime: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setApplicant({
      ...applicant,
      [name]: value,
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setApplicant({
      ...applicant,
      [name]: value,
    });
  };

  const handleLanguageChange = (selectedOptions: any) => {
    const selectedLanguages = selectedOptions.map(
      (option: any) => option.value
    );
    setApplicant({ ...applicant, language: selectedLanguages.join(", ") });
  };

  const onDropCv = (acceptedFiles: File[]) => {
    setApplicant((prev) => ({ ...prev, cv: acceptedFiles[0] }));
  };

  const onDropPortfolio = (acceptedFiles: File[]) => {
    setApplicant((prev) => ({ ...prev, portfolio: acceptedFiles[0] }));
  };

  const { getRootProps: getCvRootProps, getInputProps: getCvInputProps } =
    useDropzone({
      onDrop: onDropCv,
      // accept: { "application/pdf": [".pdf"], "application/msword": [".doc", ".docx"] },
      multiple: false,
    });

  const {
    getRootProps: getPortfolioRootProps,
    getInputProps: getPortfolioInputProps,
  } = useDropzone({
    onDrop: onDropPortfolio,
    // accept: { "application/pdf": [".pdf"], "application/msword": [".doc", ".docx"] },
    multiple: false,
  });


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
  
    for (const key in applicant) {
      const value = applicant[key as keyof Applicant];
  
      // Handle File fields
      if (key === "cv" || key === "portfolio") {
        if (value) {
          formData.append(key, value as File);
        }
      } 
      // Handle arrays (e.g., language field)
      else if (Array.isArray(value)) {
        value.forEach((item) => formData.append(`${key}[]`, item));
      } 
      // Handle other fields
      else if (value !== undefined && value !== null) {
        formData.append(key, value as string);
      }
    }
  
    try {
      const response = await fetch(
        "https://superdolphins2024.onrender.com/api/v1/hr/create-applicant",
        {
          method: "POST",
          body: formData,
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result);
      Swal.fire({
        title: "Application Submitted!",
        text: "We will get back to you!",
        icon: "success"
      });
  
      setApplicant({
        name: "",
        email: "",
        phone_no: "",
        address: "",
        department: "",
        total_exp: "",
        current_ctc: "",
        expected_ctc: "",
        ctc_rate: "",
        cv: null,
        cover_letter: "",
        portfolio: null,
        laptop_desktop: "",
        timing: "",
        language: [],
        freelancer_working: "",
        overtime: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };


  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "10px",
      border: "2px solid #ddd",
      fontSize: "1rem", // Increase font size for the select input
      padding: "0.35rem", // Increase padding for better spacing
      //   borderRadius: "0.25rem", // Rounded corners for the select box
      borderColor: "#ccc", // Set border color
      boxShadow: "none", // Remove default shadow
      "&:hover": {
        borderColor: "#007bff", // Set hover border color
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#007bff", // Background color for selected values
      color: "white", // Text color for selected values
      borderRadius: "0.25rem", // Rounded corners for multi-value items
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "white", // Text color for selected value labels
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "white", // Color for remove icon (X)
      ":hover": {
        backgroundColor: "red", // Hover color for remove icon
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : provided.backgroundColor, // Highlight selected option
      color: state.isSelected ? "white" : "black", // Text color for options
      ":hover": {
        backgroundColor: "#f1f1f1", // Hover background color
      },
    }),
  };

  

  return (
    <>
      <Container>
        <Card style={{ width: "100%", paddingBottom: "5px" }}>
          <Card.Img
            variant="top"
            src="https://images.pexels.com/photos/8235881/pexels-photo-8235881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style={{ height: "220px", objectFit: "cover" }}
          />
          <Card.Body>
            <Card.Img
              src={DIImg}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "cover",
                position: "absolute",
                top: "45%",
                border: "1px solid #fff",
              }}
            />
            <Card.Title style={{ marginTop: "70px", fontSize: "1.6rem" }}>
              Applicants Form
            </Card.Title>
          </Card.Body>
        </Card>

        <Card style={{ padding: "20px 10px" }}>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Name
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Control
                      type="text"
                      name="name"
                      value={applicant.name}
                      onChange={handleChange}
                      required
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Email
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Control
                      type="email"
                      name="email"
                      value={applicant.email}
                      onChange={handleChange}
                      required
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formPhoneNo" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Phone Number
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Control
                      type="text"
                      name="phone_no"
                      value={applicant.phone_no}
                      onChange={handleChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formAddress" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Address
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Control
                      type="textarea"
                      name="address"
                      value={applicant.address}
                      onChange={handleChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Department
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Select
                      name="department"
                      value={applicant.department}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option value="Web">Web Development</option>
                      <option value="Graphics">Graphics</option>
                      <option value="Content">Content</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Total Experience
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Select
                      name="total_exp"
                      value={applicant.total_exp}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option value="fresher">Fresher</option>
                      <option value="1-2">1-2</option>
                      <option value="2-3">2-3</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCTC" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Current CTC
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Control
                      type="number"
                      name="current_ctc"
                      value={applicant.current_ctc}
                      onChange={handleChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formCTC" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Expected CTC
                    </Form.Label>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Form.Control
                      type="number"
                      name="expected_ctc"
                      value={applicant.expected_ctc}
                      onChange={handleChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCTCRate" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Current CTC Rate
                    </Form.Label>
                    <Form.Select
                      name="ctc_rate"
                      value={applicant.ctc_rate}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option value="per hour">per Hour</option>
                      <option value="per month">per Month</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Do you have laptop/Desktop?
                    </Form.Label>
                    <Form.Select
                      name="laptop_desktop"
                      value={applicant.laptop_desktop}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option>Open this select menu</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Work timing
                    </Form.Label>
                    <Form.Select
                      name="timing"
                      value={applicant.timing}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option>Open this select menu</option>
                      <option value="full-time">Full Time (9AM to 6PM)</option>
                      <option value="part-time">
                        Part Time (9AM to 2PM/2AM to 6PM)
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>


                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Are you willing to work overtime when necessary?
                    </Form.Label>
                    <Form.Select
                      name="overtime"
                      value={applicant.overtime}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option>Open this select menu</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                
              </Row>

              <Row>
                

                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      Are you freelancer or working somewhere else?
                    </Form.Label>
                    <Form.Select
                      name="freelancer_working"
                      value={applicant.freelancer_working}
                      onChange={handleSelectChange}
                      style={{
                        fontSize: "1rem",
                        padding: "12px",
                        borderRadius: "10px",
                        border: "2px solid #ddd",
                        backgroundColor: "#f8f9fa",
                        transition: "all 0.3s",
                      }}
                    >
                      <option>Open this select menu</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                      >
                        Languages Known
                      </Form.Label>
                      <Select
                        isMulti
                        options={languageOptions}
                        onChange={handleLanguageChange}
                        className="basic-multi-select"
                        // classNamePrefix="select"
                        styles={customStyles}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row>
                

                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      CV
                    </Form.Label>
                    <div
                      {...getCvRootProps()}
                      // className="border p-3 text-center rounded"
                      style={{
                        border: "2px dashed #ccc",
                        padding: "20px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <input {...getCvInputProps()} />
                      {applicant.cv ? (
                        <p>{applicant.cv.name}</p>
                      ) : (
                        <p>Drag and drop a file, or click to select</p>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                {applicant.department === "Graphics" && (
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                      >
                        Portfolio
                      </Form.Label>
                      <div
                        {...getPortfolioRootProps()}
                        // className="border p-3 text-center rounded"
                        style={{
                          border: "2px dashed #ccc",
                          padding: "20px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        <input {...getPortfolioInputProps()} />
                        {applicant.portfolio ? (
                          <p>{applicant.portfolio.name}</p>
                        ) : (
                          <p>Drag and drop a file, or click to select</p>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <hr />

              <Card.Title style={{ marginTop: "40px", fontSize: "1.4rem" }}>
                Terms And Condition
              </Card.Title>

              <Card.Text
                style={{
                  marginTop: "30px",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                }}
              >
                {/* Your terms and conditions text here */}
              </Card.Text>

              <Form.Check
                inline
                label="I Agree with the above Terms and conditions"
                name="group1"
              />

              <hr />

              <div className="mt-6 d-flex justify-content-center gap-2">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Apply"}
                </Button>
                <Button variant="danger">Cancel</Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Container>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default ApplicantForm;
