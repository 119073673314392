import { Alert } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";

const Leaves = () => {


  return (
    <>
        <PageTitle
        breadCrumbItems={[{ label: "Leaves", path: "/hr/leaves", active: true }]}
        title={"Leaves"}
      />

<Alert variant="info">
      <p>No Leaves </p>
    </Alert>
    </>
  );
};

export default Leaves;