
import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Card,
  Spinner,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import PageTitle from "../../components/PageTitle";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

const CompaniesForm: React.FC = () => {
  const [companyName, setCompanyName] = useState("");
  const [appName, setAppName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState<File | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // Alert message state
  const [alertVariant, setAlertVariant] = useState<"success" | "danger">(
    "danger"
  ); // Alert variant state

  // console.log(mailService);
  

  const { companyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (companyId) {
      fetch(`https://superdolphins2024.onrender.com/api/v1/companies/get/${companyId}`)
        .then((response) => response.json())
        .then((data) => {
          const company = data.data.company;
          console.log(company);
          
          setCompanyName(company.company_name);
          setAppName(company.app_name || "");
          setCompanyEmail(company.company_email);
          setCompanyPhone(company.company_phone);
          setAddress(company.address)
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    }
  }, [companyId]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setLogo(acceptedFiles[0]);
      }
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("app_name", appName);
    formData.append("company_email", companyEmail);
    formData.append("company_phone", companyPhone);
    formData.append("address", address);

    if (logo) {
      formData.append("logo", logo);
    }


    setIsSubmitting(true);

    const requestMethod = companyId ? "PUT" : "POST";
    const url = companyId
      ? `https://ansh-costing.onrender.com/api/v1/companies/update-company/${companyId}`
      : `https://superdolphins2024.onrender.com/api/v1/companies/create-company`;

    fetch(url, {
      method: requestMethod,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            title: "Success!",
            text: "Company saved successfully.",
            // icon: "success",
          });
          navigate("/companies/all");
        } else {
          Swal.fire({
            // title: "Error!",
            text: data.message || "An unknown error occurred.",
            // icon: "error",
            confirmButtonText: "Okay",
          });
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error saving company:", error);

        Swal.fire({
          // title: "Error!",
          text: error.message || "An error occurred while saving the company.",
          // icon: "error",
          confirmButtonText: "Okay",
        });

        setIsSubmitting(false);
      });
  };


  return (
    <div className="mb-3">
      <PageTitle
        breadCrumbItems={[
          { label: "Companies", path: "/hr/companies", active: true },
        ]}
        title={companyId ? "Edit Company" : "Create Company"}
      />

      <Card className="p-4">
        {alertMessage && (
          <ToastContainer
            position="top-center"
            className="p-3"
            style={{
              zIndex: 1050, // Ensures the toast is above other elements
            }}
          >
            <Toast
              bg={alertVariant} // Use 'success', 'danger', 'info', etc. as per Bootstrap
              onClose={() => setAlertMessage(null)}
              show={!!alertMessage}
              delay={5000} // Auto-hide after 5 seconds
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Notification</strong>
              </Toast.Header>
              <Toast.Body>{alertMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="companyName">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Company Name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  style={{
                    padding: "0.75rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="appName">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  App Name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter app name"
                  value={appName}
                  onChange={(e) => setAppName(e.target.value)}
                  style={{
                    padding: "0.75rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="email">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Email <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  required
                  style={{
                    padding: "0.75rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Phone 
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter phone"
                  value={companyPhone}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                  style={{
                    padding: "0.75rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                />
              </Form.Group>
            </Col>


            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Address
                </Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{
                    padding: "0.75rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                />
              </Form.Group>
            </Col>

            {/* <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="mailService">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Mail Service <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={mailService}
                  onChange={(e) => setMailService(e.target.value)}
                  required
                  style={{
                    padding: "0.75rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                >
                  <option value="">Select Mail Service</option>
                  <option value="Gmail">Gmail</option>
                  <option value="SMTP">SMTP</option>
                </Form.Control>
              </Form.Group>
            </Col> */}



            <Col md={12} xs={12} className="mb-3">
              <Form.Group controlId="logo">
                <Form.Label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Logo <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div
                  {...getRootProps({ className: "dropzone" })}
                  style={{
                    border: "2px dashed #ccc",
                    padding: "20px",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  <input {...getInputProps()} />
                  <p>Drag & Drop or Click to select a file</p>
                </div>
                {logo && (
                  <div className="mt-3 d-flex justify-content-center">
                    <Image
                      src={URL.createObjectURL(logo)}
                      alt="Logo Preview"
                      thumbnail
                      width={100}
                    />
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col
              md={12}
              className="mt-4 d-flex gap-2 align-center justify-content-center"
            >
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </>
                ) : companyId ? (
                  "Update"
                ) : (
                  "Submit"
                )}
              </Button>

              <Link to={"/companies"}>
                <Button variant="info">Cancel</Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CompaniesForm;
