// import React, { useState, useEffect } from 'react';
// import { Table, Form, Button, Dropdown, Badge, Modal, Spinner, ToastContainer, Toast } from 'react-bootstrap';
// import { useTable, usePagination, Column, CellProps } from 'react-table';
// import PageTitle from '../../../components/PageTitle';

// export interface User {
//   id: number;
//   name: string;
//   email: string;
//   phone_no: string;
//   address: string;
//   department: string;
//   total_exp: number;
//   current_ctc: number;
//   ctc_rate: number;
//   expected_ctc: number;
//   cv: string;
//   cover_letter: string;
//   portfolio: string;
//   status: string | null;
// }

// const Applicants: React.FC = () => {
//   const [data, setData] = useState<User[]>([]);
//   const [filter, setFilter] = useState<string>('');
//   const [statusFilter, setStatusFilter] = useState<string>('');
//   const [departmentFilter, setDepartmentFilter] = useState<string>('');
//   const [pageSize, setPageSize] = useState<number>(10);
//   const [loading, setLoading] = useState<boolean>(true); // Loading state

//   // Modal states
//   const [showModal, setShowModal] = useState(false);
//   const [showDetailsModal, setShowDetailsModal] = useState(false);
//   const [selectedApplicant, setSelectedApplicant] = useState<User | null>(null);
//   const [newStatus, setNewStatus] = useState<string>('');

//    // Toast states
//    const [showToast, setShowToast] = useState(false);
//    const [toastMessage, setToastMessage] = useState('');
//    const [toastVariant, setToastVariant] = useState<'success' | 'danger'>('success');

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch('https://superdolphins2024.onrender.com/api/v1/hr/get-applicants');
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         const result = await response.json();
//         setData(result.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const filteredData = React.useMemo(
//     () => data.filter(row =>
//       Object.values(row).some(value =>
//         String(value).toLowerCase().includes(filter.toLowerCase())
//       ) &&
//       (statusFilter ? row.status === statusFilter : true) &&
//       (departmentFilter ? row.department === departmentFilter : true)
//     ),
//     [data, filter, statusFilter, departmentFilter]
//   );

//   const handleShowModal = (applicant: User) => {
//     setSelectedApplicant(applicant);
//     setNewStatus(applicant.status || '');
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedApplicant(null);
//   };

//   const handleShowDetailsModal = (applicant: User) => {
//     setSelectedApplicant(applicant);
//     setShowDetailsModal(true);
//   };

//   const handleCloseDetailsModal = () => {
//     setShowDetailsModal(false);
//     setSelectedApplicant(null);
//   };

//   const showToastMessage = (message: string, variant: 'success' | 'danger') => {
//     setToastMessage(message);
//     setToastVariant(variant);
//     setShowToast(true);
//   };

//   const handleStatusChange = async () => {
//     if (selectedApplicant) {
//       try {
//         const response = await fetch(`https://superdolphins2024.onrender.com/api/v1/hr/applicant-status-update/${selectedApplicant.id}`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ status: newStatus }),
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const updatedData = data.map(applicant =>
//           applicant.id === selectedApplicant.id ? { ...applicant, status: newStatus } : applicant
//         );
//         setData(updatedData);
//         showToastMessage('Status updated successfully!', 'success');
//         handleCloseModal();
//       } catch (error) {
//         console.error('Error updating status:', error);
//         showToastMessage('Error updating status. Please try again.', 'danger');
//       }
//     }
//   };

//   const handleDelete = async (id: number) => {
//     if (window.confirm('Are you sure you want to delete this applicant?')) {
//         try {
//             const response = await fetch(`https://superdolphins2024.onrender.com/api/v1/hr/applicant-delete/${id}`, {
//                 method: 'DELETE',
//             });

//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }

//             // Update the local state by filtering out the deleted applicant
//             const updatedData = data.filter(applicant => applicant.id !== id);
//             setData(updatedData);
//             showToastMessage('Applicant deleted successfully!', 'success');
//         } catch (error) {
//             console.error('Error deleting applicant:', error);
//             showToastMessage('Error deleting applicant. Please try again.', 'danger');
//         }
//     }
// };

//   const columns: Column<User>[] = React.useMemo(
//     () => [
//       { Header: 'ID', accessor: 'id' },
//       { Header: 'Name', accessor: 'name' },
//       { Header: 'Email', accessor: 'email' },
//       { Header: 'Phone No', accessor: 'phone_no' },
//       { Header: 'Department', accessor: 'department' },
//       {
//         Header: 'CV',
//         accessor: 'cv',
//         Cell: ({ cell }: CellProps<User>) => (
//           <Badge style={{ padding: "9px", fontSize: "12px", cursor: "pointer" }} bg='info' onClick={() => alert(`View CV for ID: ${cell.row.original.cv}`)}>
//             View CV
//           </Badge>
//         ),
//       },
//       {
//         Header: 'Status',
//         accessor: 'status',
//         Cell: ({ cell }: CellProps<User>) => (
//           <Badge
//             style={{ padding: "9px", fontSize: "12px", cursor: "pointer" }}
//             bg={
//               cell.value === 'Application Submitted' ? 'info' :
//               cell.value === 'Interview Scheduled' ? 'secondary' :
//               cell.value === 'Offer Made' ? 'primary' :
//               cell.value === 'Hired' ? 'success' :
//               cell.value === 'Rejected' ? 'danger' :
//               'secondary'
//             }
//             onClick={() => handleShowModal(cell.row.original)}
//           >
//             {cell.value}
//           </Badge>
//         ),
//       },
//       {
//         Header: 'Action',
//         Cell: ({ row }: CellProps<User>) => (
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary" size="sm">
//               <i className="bi bi-three-dots-vertical"></i>
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item onClick={() => handleShowDetailsModal(row.original)}>View Details</Dropdown.Item>
//               <Dropdown.Item onClick={() => alert(`Edit ${row.original.id}`)}>Edit</Dropdown.Item>
//               <Dropdown.Item onClick={() => handleDelete(row.original.id)}>Delete</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         ),
//       },
//     ],
//     [data]
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     gotoPage,
//     nextPage,
//     previousPage,
//     state: { pageIndex },
//   } = useTable<User>(
//     {
//       columns,
//       data: filteredData,
//       initialState: { pageIndex: 0, pageSize },
//     },
//     usePagination
//   );

//   return (
//     <div className="mb-4">
//       <PageTitle
//         breadCrumbItems={[{ label: "Applicants", path: "/hr/employee", active: true }]}
//         title={"Applicants"}
//       />
//       {loading ? (
//         <div className="d-flex justify-content-center mt-5">
//           <Spinner animation="border" role="status" />
//         </div>
//       ) : (
//         <>
//           <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
//             <div className='d-flex align-items-center'>
//               <Form.Group controlId="search" className="me-2">
//                 <Form.Label>Search:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Search by name, email, or role"
//                   value={filter}
//                   onChange={(e) => setFilter(e.target.value)}
//                 />
//               </Form.Group>
//               <Form.Group controlId="statusFilter" className="mx-2">
//                 <Form.Label>Status Filter:</Form.Label>
//                 <Form.Select
//                   value={statusFilter}
//                   onChange={(e) => setStatusFilter((e.target as HTMLSelectElement).value)}
//                 >
//                   <option value="">All</option>
//                   <option value="Application Submitted">Application Submitted</option>
//                   <option value="Interview Scheduled">Interview Scheduled</option>
//                   <option value="Offer Made">Offer Made</option>
//                   <option value="Hired">Hired</option>
//                   <option value="Rejected">Rejected</option>
//                 </Form.Select>
//               </Form.Group>
//               <Form.Group controlId="departmentFilter" className="mx-2">
//                 <Form.Label>Department Filter:</Form.Label>
//                 <Form.Select
//                   value={departmentFilter}
//                   onChange={(e) => setDepartmentFilter((e.target as HTMLSelectElement).value)}
//                 >
//                   <option value="">All</option>
//                   <option value="Engineering">Engineering</option>
//                   <option value="Product">Product</option>
//                   <option value="Design">Design</option>
//                   <option value="Quality Assurance">Quality Assurance</option>
//                   <option value="Data Science">Data Science</option>
//                   <option value="Marketing">Marketing</option>
//                   <option value="IT">IT</option>
//                 </Form.Select>
//               </Form.Group>
//             </div>

//             <div>
//     <Button variant="primary">
//       Add Applicants
//     </Button>
//   </div>

//           </div>
//           <div className="table-responsive">
//             <Table {...getTableProps()} striped bordered hover className="mt-3">
//               <thead>
//                 {headerGroups.map(headerGroup => (
//                   <tr {...headerGroup.getHeaderGroupProps()}>
//                     {headerGroup.headers.map(column => (
//                       <th {...column.getHeaderProps()}>{column.render('Header')}</th>
//                     ))}
//                   </tr>
//                 ))}
//               </thead>
//               <tbody {...getTableBodyProps()}>
//                 {page.length > 0 ? (
//                   page.map(row => {
//                     prepareRow(row);
//                     return (
//                       <tr {...row.getRowProps()}>
//                         {row.cells.map(cell => (
//                           <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                         ))}
//                       </tr>
//                     );
//                   })
//                 ) : (
//                   <tr>
//                     <td colSpan={columns.length} className="text-center">No results found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </Table>
//           </div>

//           {/* Modal for Status Change */}
//           <Modal show={showModal} onHide={handleCloseModal}>
//             <Modal.Header closeButton>
//               <Modal.Title>Change Status</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <Form.Group controlId="formStatus">
//                 <Form.Label>Status</Form.Label>
//                 <Form.Select value={newStatus} onChange={(e) => setNewStatus((e.target as HTMLSelectElement).value)}>
//                   <option value="Application Submitted">Application Submitted</option>
//                   <option value="Interview Scheduled">Interview Scheduled</option>
//                   <option value="Offer Made">Offer Made</option>
//                   <option value="Hired">Hired</option>
//                   <option value="Rejected">Rejected</option>
//                 </Form.Select>
//               </Form.Group>
//             </Modal.Body>
//             <Modal.Footer>
//               <Button variant="secondary" onClick={handleCloseModal}>
//                 Close
//               </Button>
//               <Button variant="primary" onClick={handleStatusChange}>
//                 Save Changes
//               </Button>
//             </Modal.Footer>
//           </Modal>

//           {/* Modal for Viewing Applicant Details */}
//           <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
//             <Modal.Header closeButton>
//               <Modal.Title>Applicant Details</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               {selectedApplicant && (
//                <div style={{ fontSize: '16px', lineHeight: '1.5' }}>
//                <h5 style={{ marginBottom: '10px' }}>Name: {selectedApplicant.name}</h5>
//                <p style={{ marginBottom: '10px' }}>Email: {selectedApplicant.email}</p>
//                <p style={{ marginBottom: '10px' }}>Phone No: {selectedApplicant.phone_no}</p>
//                <p style={{ marginBottom: '10px' }}>Address: {selectedApplicant.address}</p>
//                <p style={{ marginBottom: '10px' }}>Department: {selectedApplicant.department}</p>
//                <p style={{ marginBottom: '10px' }}>Total Experience: {selectedApplicant.total_exp} years</p>
//                <p style={{ marginBottom: '10px' }}>Current CTC: ₹{selectedApplicant.current_ctc}</p>
//                <p style={{ marginBottom: '10px' }}>Expected CTC: ₹{selectedApplicant.expected_ctc}</p>
//                <p style={{ marginBottom: '10px' }}>
//                  CV: <a href={selectedApplicant.cv} target="_blank" rel="noopener noreferrer">Download</a>
//                </p>
//                <p style={{ marginBottom: '10px' }}>Cover Letter: {selectedApplicant.cover_letter}</p>
//                {selectedApplicant.department === "Graphics" && (
//                  <p style={{ marginBottom: '10px' }}>
//                    Portfolio: <a href={selectedApplicant.portfolio} target="_blank" rel="noopener noreferrer">Download</a>
//                  </p>
//                )}
//                <p style={{ marginBottom: '10px' }}>Status: {selectedApplicant.status}</p>
//              </div>
//               )}
//             </Modal.Body>
//             <Modal.Footer>
//               <Button variant="secondary" onClick={handleCloseDetailsModal}>
//                 Close
//               </Button>
//             </Modal.Footer>
//           </Modal>

//           <div className="d-flex justify-content-between align-items-center mt-3">
//             <div>
//               <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} variant="outline-secondary">
//                 {'<<'}
//               </Button>{' '}
//               <Button onClick={() => previousPage()} disabled={!canPreviousPage} variant="outline-secondary">
//                 {'<'}
//               </Button>{' '}
//               <Button onClick={() => nextPage()} disabled={!canNextPage} variant="outline-secondary">
//                 {'>'}
//               </Button>{' '}
//               <Button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} variant="outline-secondary">
//                 {'>>'}
//               </Button>
//             </div>
//             <div>
//               Page{' '}
//               <strong>
//                 {pageIndex + 1} of {pageOptions.length}
//               </strong>
//             </div>
//             <div>
//               <Form.Select
//                 value={pageSize}
//                 onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
//                   setPageSize(Number(e.target.value));
//                 }}
//                 style={{ width: 'auto' }}
//               >
//                 {[5, 10, 20].map(size => (
//                   <option key={size} value={size}>
//                     Show {size}
//                   </option>
//                 ))}
//               </Form.Select>
//             </div>
//           </div>
//         </>
//       )}

//       {/* Toast Notifications */}
//       <ToastContainer position="top-end" className="p-3">
//         <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg={toastVariant}>
//           <Toast.Body>{toastMessage}</Toast.Body>
//         </Toast>
//       </ToastContainer>
//     </div>
//   );
// };

// export default Applicants;

// -----------------------------------------

import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  Dropdown,
  Badge,
  Modal,
  Spinner,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useTable, usePagination, Column, CellProps } from "react-table";
import PageTitle from "../../../components/PageTitle";
import { Link } from "react-router-dom";

export interface User {
  id: number;
  name: string;
  email: string;
  phone_no: string;
  address: string;
  department: string;
  total_exp: number;
  current_ctc: number;
  ctc_rate: number;
  expected_ctc: number;
  cv: string;
  cover_letter: string;
  portfolio: string;
  status: string | null;
}

const Applicants: React.FC = () => {
  const [data, setData] = useState<User[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [departmentFilter, setDepartmentFilter] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<User | null>(null);
  const [newStatus, setNewStatus] = useState<string>("");

  // Interview form states
  const [interviewName, setInterviewName] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewTime, setInterviewTime] = useState("");
  const [googleMeetLink, setGoogleMeetLink] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [department, setDepartment] = useState("");

  // Toast states
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState<"success" | "danger">(
    "success"
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://superdolphins2024.onrender.com/api/v1/hr/get-applicants"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = React.useMemo(
    () =>
      data.filter(
        (row) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(filter.toLowerCase())
          ) &&
          (statusFilter ? row.status === statusFilter : true) &&
          (departmentFilter ? row.department === departmentFilter : true)
      ),
    [data, filter, statusFilter, departmentFilter]
  );

  const handleShowModal = (applicant: User) => {
    setSelectedApplicant(applicant);
    setNewStatus(applicant.status || "");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedApplicant(null);
    setInterviewName("");
    setInterviewDate("");
    setInterviewTime("");
    setGoogleMeetLink("");
    setApplicantName("");
    setDepartment("");
  };

  const handleShowDetailsModal = (applicant: User) => {
    setSelectedApplicant(applicant);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedApplicant(null);
  };

  const showToastMessage = (message: string, variant: "success" | "danger") => {
    setToastMessage(message);
    setToastVariant(variant);
    setShowToast(true);
  };

  const handleStatusChange = async () => {
    if (selectedApplicant) {
      try {
        const response = await fetch(
          `https://superdolphins2024.onrender.com/api/v1/hr/applicant-status-update/${selectedApplicant.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status: newStatus }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const updatedData = data.map((applicant) =>
          applicant.id === selectedApplicant.id
            ? { ...applicant, status: newStatus }
            : applicant
        );
        setData(updatedData);
        showToastMessage("Status updated successfully!", "success");
        handleCloseModal();
      } catch (error) {
        console.error("Error updating status:", error);
        showToastMessage("Error updating status. Please try again.", "danger");
      }
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this applicant?")) {
      try {
        const response = await fetch(
          `https://superdolphins2024.onrender.com/api/v1/hr/applicant-delete/${id}`,
          {
            method: "DELETE",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Update the local state by filtering out the deleted applicant
        const updatedData = data.filter((applicant) => applicant.id !== id);
        setData(updatedData);
        showToastMessage("Applicant deleted successfully!", "success");
      } catch (error) {
        console.error("Error deleting applicant:", error);
        showToastMessage(
          "Error deleting applicant. Please try again.",
          "danger"
        );
      }
    }
  };

  const columns: Column<User>[] = React.useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone No", accessor: "phone_no" },
      { Header: "Department", accessor: "department" },
      {
        Header: "CV",
        accessor: "cv",
        Cell: ({ cell }: CellProps<User>) => (
          <Badge
            style={{ padding: "9px", fontSize: "12px", cursor: "pointer" }}
            bg="info"
          >
            <a
              href={cell.row.original.cv}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#fff" }}
            >
              View CV
            </a>
          </Badge>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }: CellProps<User>) => (
          <Badge
            style={{ padding: "9px", fontSize: "12px", cursor: "pointer" }}
            bg={
              cell.value === "Application Submitted"
                ? "info"
                : cell.value === "Interview Scheduled"
                ? "secondary"
                : cell.value === "Offer Made"
                ? "primary"
                : cell.value === "Hired"
                ? "success"
                : cell.value === "Rejected"
                ? "danger"
                : "secondary"
            }
            onClick={() => handleShowModal(cell.row.original)}
          >
            {cell.value}
          </Badge>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }: CellProps<User>) => (
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" size="sm">
              <i className="bi bi-three-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleShowDetailsModal(row.original)}
              >
                View Details
              </Dropdown.Item>
              <Dropdown.Item onClick={() => alert(`Edit ${row.original.id}`)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDelete(row.original.id)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable<User>(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize },
    },
    usePagination
  );

  // Auto-generate Google Meet Link
  const generateGoogleMeetLink = () => {
    setGoogleMeetLink(
      `https://meet.google.com/${Math.random().toString(36).substring(7)}`
    );
  };

  return (
    <div className="mb-4">
      <PageTitle
        breadCrumbItems={[
          { label: "Applicants", path: "/hr/employee", active: true },
        ]}
        title={"Applicants"}
      />
      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
            <div className="d-flex align-items-center">
              <Form.Group controlId="search" className="me-2">
                <Form.Label>Search:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Search by name, email, or role"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="statusFilter" className="mx-2">
                <Form.Label>Status Filter:</Form.Label>
                <Form.Select
                  value={statusFilter}
                  onChange={(e) =>
                    setStatusFilter((e.target as HTMLSelectElement).value)
                  }
                >
                  <option value="">All</option>
                  <option value="Application Submitted">
                    Application Submitted
                  </option>
                  <option value="Interview Scheduled">
                    Interview Scheduled
                  </option>
                  <option value="Offer Made">Offer Made</option>
                  <option value="Hired">Hired</option>
                  <option value="Rejected">Rejected</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="departmentFilter" className="mx-2">
                <Form.Label>Department Filter:</Form.Label>
                <Form.Select
                  value={departmentFilter}
                  onChange={(e) =>
                    setDepartmentFilter((e.target as HTMLSelectElement).value)
                  }
                >
                  <option value="">All</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Product">Product</option>
                  <option value="Design">Design</option>
                  <option value="Quality Assurance">Quality Assurance</option>
                  <option value="Data Science">Data Science</option>
                  <option value="Marketing">Marketing</option>
                  <option value="IT">IT</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div>
              <Button variant="primary">
                <a
                  href="https://crm.superdolphins.com/applicants-form"
                  style={{ color: "#fff" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add Applicants
                </a>
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table {...getTableProps()} striped bordered hover className="mt-3">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {/* Modal for Status Change */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Change Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  value={newStatus}
                  onChange={(e) =>
                    setNewStatus((e.target as HTMLSelectElement).value)
                  }
                >
                  <option value="Application Submitted">
                    Application Submitted
                  </option>
                  <option value="Interview Scheduled">
                    Interview Scheduled
                  </option>
                  <option value="Offer Made">Offer Made</option>
                  <option value="Hired">Hired</option>
                  <option value="Rejected">Rejected</option>
                </Form.Select>
              </Form.Group>

              {newStatus === "Interview Scheduled" && (
                <>
                  <Form.Group controlId="interviewName" className="mt-4">
                    <Form.Label>Interview Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={interviewName}
                      onChange={(e) => setInterviewName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="interviewDate" className="mt-2">
                    <Form.Label>Interview Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={interviewDate}
                      onChange={(e) => setInterviewDate(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="interviewTime" className="mt-2">
                    <Form.Label>Interview Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={interviewTime}
                      onChange={(e) => setInterviewTime(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="secondary"
                    onClick={generateGoogleMeetLink}
                    className="mt-2"
                  >
                    Generate Google Meet Link
                  </Button>
                  {googleMeetLink && (
                    <Form.Group controlId="googleMeetLink" className="mt-4">
                      <Form.Label>Google Meet Link</Form.Label>
                      <Form.Control
                        type="text"
                        value={googleMeetLink}
                        disabled
                      />
                    </Form.Group>
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleStatusChange}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for Viewing Applicant Details */}
          <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
            <Modal.Header closeButton>
              <Modal.Title>Applicant Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedApplicant && (
                <div style={{ fontSize: "16px", lineHeight: "1.5" }}>
                  <h5 style={{ marginBottom: "10px" }}>
                    Name: {selectedApplicant.name}
                  </h5>
                  <p style={{ marginBottom: "10px" }}>
                    Email: {selectedApplicant.email}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Phone No: {selectedApplicant.phone_no}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Address: {selectedApplicant.address}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Department: {selectedApplicant.department}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Total Experience: {selectedApplicant.total_exp} years
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Current CTC: ₹{selectedApplicant.current_ctc}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Expected CTC: ₹{selectedApplicant.expected_ctc}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    CV:{" "}
                    <a
                      href={selectedApplicant.cv}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Cover Letter: {selectedApplicant.cover_letter}
                  </p>
                  {selectedApplicant.department === "Graphics" && (
                    <p style={{ marginBottom: "10px" }}>
                      Portfolio:{" "}
                      <a
                        href={selectedApplicant.portfolio}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    </p>
                  )}
                  <p style={{ marginBottom: "10px" }}>
                    Status: {selectedApplicant.status}
                  </p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDetailsModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              <Button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                variant="outline-secondary"
              >
                {"<<"}
              </Button>{" "}
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                variant="outline-secondary"
              >
                {"<"}
              </Button>{" "}
              <Button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                variant="outline-secondary"
              >
                {">"}
              </Button>{" "}
              <Button
                onClick={() => gotoPage(pageOptions.length - 1)}
                disabled={!canNextPage}
                variant="outline-secondary"
              >
                {">>"}
              </Button>
            </div>
            <div>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <div>
              <Form.Select
                value={pageSize}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ width: "auto" }}
              >
                {[5, 10, 20].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </>
      )}

      {/* Toast Notifications */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default Applicants;
