import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import DIImg from "../../../assets/images/diImg.png";
// import Footer from "../../../layouts/Footer";
import SignatureBox from "../../../components/SignatureComponent";
import { useParams } from "react-router-dom";

interface JoiningFormInterface {
  full_name: string;
  email_address: string;
  telephone_number?: string;
  permanent_address: {
    street: string;
    city: string;
    state: string;
    postal_code: string;
  };

  current_address: {
    street: string;
    city: string;
    state: string;
    postal_code: string;
  };

  gender?: string;
  date_of_birth?: string;
  nationality?: string;
  marital_status?: string;
  emergency_contact_name?: string;
  emergency_relationship?: string;
  emergency_telephone?: string;
  emergency_email?: string;
  emergency_address?: string;
  parent_name?: string;
  parent_contact_number?: string;
  parent_job_profile?: string;
  parent_address?: string;
  education_school_university?: string;
  education_degree_diploma?: string;
  education_year_of_graduation?: string;
  bank_name?: string;
  account_number?: string;
  account_holder_name?: string;
  bank_branch?: string;
  blood_group?: string;
  medical_conditions?: string;
  medical_insurance?: string;
  reference_name?: string;
  reference_relationship?: string;
  reference_telephone?: string;
  reference_email?: string;
  declaration_signature?: string;
  declaration_date?: string;
  employmentHistory: {
    previous_company: string;
    previous_position: string;
    previous_dates_of_employment: string;
    previous_reason_for_leaving: string;
    supervisor_name: string;
    company_phone_no: string;
    company_address: string;
  }[];
}

const JoiningForm: React.FC = () => {
  const [formData, setFormData] = useState<JoiningFormInterface>({
    full_name: "",
    email_address: "",
    telephone_number: "",
    permanent_address: {
      street: "",
      city: "",
      state: "",
      postal_code: "",
    },

    current_address: {
      street: "",
      city: "",
      state: "",
      postal_code: "",
    },
    gender: "",
    date_of_birth: "",
    nationality: "",
    marital_status: "",
    emergency_contact_name: "",
    emergency_relationship: "",
    emergency_telephone: "",
    emergency_email: "",
    emergency_address: "",
    parent_name: "",
    parent_contact_number: "",
    parent_job_profile: "",
    parent_address: "",
    education_school_university: "",
    education_degree_diploma: "",
    education_year_of_graduation: "",
    bank_name: "",
    account_number: "",
    account_holder_name: "",
    bank_branch: "",
    blood_group: "",
    medical_conditions: "",
    medical_insurance: "",
    reference_name: "",
    reference_relationship: "",
    reference_telephone: "",
    reference_email: "",
    declaration_signature: "",
    declaration_date: "",
    employmentHistory: [
      {
        previous_company: "",
        previous_position: "",
        previous_dates_of_employment: "",
        previous_reason_for_leaving: "",
        supervisor_name: "",
        company_phone_no: "",
        company_address: "",
      },
    ],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>("");
  const [isSameAsCurrent, setIsSameAsCurrent] = useState(false);

  const handleCheckboxChange = () => {
    setIsSameAsCurrent((prevState) => {
      if (!prevState) {
        // Sync Permanent Address with Current Address
        setFormData((prevData) => ({
          ...prevData,
          permanent_address: { ...prevData.current_address }, // Copy current address to permanent address
        }));
      } else {
        // Clear Permanent Address when unchecked
        setFormData((prevData) => ({
          ...prevData,
          permanent_address: {
            street: "",
            city: "",
            state: "",
            postal_code: "",
          },
        }));
      }
      return !prevState; // Toggle the checkbox state
    });
  };

  const handleSignatureSave = (dataURL: string) => {
    setSignature(dataURL);
  };

  const { name } = useParams();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (
      name.startsWith("current_address") ||
      name.startsWith("permanent_address")
    ) {
      const [addressType, field] = name.split(".");

      setFormData((prevData) => ({
        ...prevData,
        [addressType as "current_address" | "permanent_address"]: {
          ...prevData[addressType as "current_address" | "permanent_address"],
          [field]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEmploymentChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const updatedEmploymentHistory = [...formData.employmentHistory];
    updatedEmploymentHistory[index] = {
      ...updatedEmploymentHistory[index],
      [name]: value,
    };
    setFormData({ ...formData, employmentHistory: updatedEmploymentHistory });
  };

  const addEmploymentEntry = () => {
    setFormData({
      ...formData,
      employmentHistory: [
        ...formData.employmentHistory,
        {
          previous_company: "",
          previous_position: "",
          previous_dates_of_employment: "",
          previous_reason_for_leaving: "",
          supervisor_name: "",
          company_phone_no: "",
          company_address: "",
        },
      ],
    });
  };

  const removeEmploymentEntry = (index: number) => {
    const updatedEmploymentHistory = formData.employmentHistory.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, employmentHistory: updatedEmploymentHistory });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // const formSubmissionData = new FormData();
    // for (const key in formData) {
    //   const value = formData[key as keyof JoiningForm];
    //   if (value !== null && value !== undefined) {
    //     formSubmissionData.append(key, value);
    //   }
    // }

    const formDataSubmit = new FormData();
    for (const key in formData) {
      const value = formData[key as keyof JoiningFormInterface];

      // If the value is an array or object (e.g., employmentHistory), convert it to JSON string
      if (Array.isArray(value) || typeof value === "object") {
        formDataSubmit.append(key, JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        formDataSubmit.append(key, value);
      }
    }

    try {
      const response = await fetch(
        "https://superdolphins2024.onrender.com/api/v1/hr/create-joining-form",
        {
          method: "POST",
          body: formDataSubmit,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);

      // Reset the form
      setFormData({
        full_name: "",
        email_address: "",
        telephone_number: "",
        permanent_address: {
          street: "",
          city: "",
          state: "",
          postal_code: "",
        },

        current_address: {
          street: "",
          city: "",
          state: "",
          postal_code: "",
        },
        gender: "",
        date_of_birth: "",
        nationality: "",
        marital_status: "",
        emergency_contact_name: "",
        emergency_relationship: "",
        emergency_telephone: "",
        emergency_email: "",
        emergency_address: "",
        parent_name: "",
        parent_contact_number: "",
        parent_job_profile: "",
        parent_address: "",
        education_school_university: "",
        education_degree_diploma: "",
        education_year_of_graduation: undefined,

        bank_name: "",
        account_number: "",
        account_holder_name: "",
        bank_branch: "",
        blood_group: "",
        medical_conditions: "",
        medical_insurance: "",
        reference_name: "",
        reference_relationship: "",
        reference_telephone: "",
        reference_email: "",
        declaration_signature: "",
        declaration_date: "",

        employmentHistory: [
          {
            previous_company: "",
            previous_position: "",
            previous_dates_of_employment: "",
            previous_reason_for_leaving: "",
            supervisor_name: "",
            company_phone_no: "",
            company_address: "",
          },
        ],
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Card style={{ width: "100%", paddingBottom: "5px" }}>
          <Card.Img
            variant="top"
            src="https://images.pexels.com/photos/8235881/pexels-photo-8235881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style={{ height: "220px", objectFit: "cover" }}
          />
          <Card.Body>
            <Card.Img
              src={DIImg}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "cover",
                position: "absolute",
                top: "45%",
                border: "1px solid #fff",
              }}
            />
            <Card.Title style={{ marginTop: "70px", fontSize: "1.7rem" }}>
              Joining Form
            </Card.Title>

            <Card.Text style={{ marginTop: "15px", fontSize: "1rem" }}>
              Please note: By submitting this form you acknowledge that you are
              not working elsewhere or freelancing at this time.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ padding: "20px 10px" }}>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <h2
                style={{
                  marginTop: "10px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Personal Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formFullName" className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formGender" className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      name="gender"
                      value={formData.gender}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formDateOfBirth" className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_of_birth"
                      value={formData.date_of_birth}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formNationality" className="mb-3">
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                      type="text"
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formMaritalStatus" className="mb-3">
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Select
                      name="marital_status"
                      value={formData.marital_status}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {/* ******************************************contact ********************************************************************************************************88*/}
              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Contact Information
              </h2>

              {/* Current Address Section */}
              <h4
                style={{
                  marginTop: "30px",
                  marginBottom: "20px",
                  fontSize: "1rem",
                }}
              >
                Current Address
              </h4>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formCurrentStreet" className="mb-3">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="current_address.street"
                      value={formData.current_address.street}
                      onChange={handleChange}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formCurrentCity" className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="current_address.city"
                      value={formData.current_address.city}
                      onChange={handleChange}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formCurrentState" className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="current_address.state"
                      value={formData.current_address.state}
                      onChange={handleChange}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formCurrentPostalCode"
                    className="mb-3"
                  >
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="current_address.postal_code"
                      value={formData.current_address.postal_code}
                      onChange={handleChange}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Checkbox: Same as Current Address */}
              <Form.Group controlId="formSameAsCurrent" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Permanent Address is the same as Current Address"
                  checked={isSameAsCurrent}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              {/* Permanent Address Section */}
              <h4
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontSize: "1rem",
                }}
              >
                Permanent Address
              </h4>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formPermanentStreet" className="mb-3">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="permanent_address.street"
                      value={formData.permanent_address.street}
                      onChange={handleChange}
                      placeholder=""
                      disabled={isSameAsCurrent}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formPermanentCity" className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="permanent_address.city"
                      value={formData.permanent_address.city}
                      onChange={handleChange}
                      placeholder=""
                      disabled={isSameAsCurrent}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formPermanentState" className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="permanent_address.state"
                      value={formData.permanent_address.state}
                      onChange={handleChange}
                      placeholder=""
                      disabled={isSameAsCurrent}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formPermanentPostalCode"
                    className="mb-3"
                  >
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="permanent_address.postal_code"
                      value={formData.permanent_address.postal_code}
                      onChange={handleChange}
                      placeholder=""
                      disabled={isSameAsCurrent}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Parent/ Guardian Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formParentName" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_name"
                      value={formData.parent_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formParentContact" className="mb-3">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_contact_number"
                      value={formData.parent_contact_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formParentJobProfile" className="mb-3">
                    <Form.Label>Job Profile</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_job_profile"
                      value={formData.parent_job_profile}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formParentAddress" className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_address"
                      value={formData.parent_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Education Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group
                    controlId="formEducationSchoolUniversity"
                    className="mb-3"
                  >
                    <Form.Label>School/University</Form.Label>
                    <Form.Control
                      type="text"
                      name="education_school_university"
                      value={formData.education_school_university}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formEducationDegreeDiploma"
                    className="mb-3"
                  >
                    <Form.Label>Degree/Diploma</Form.Label>
                    <Form.Control
                      type="text"
                      name="education_degree_diploma"
                      value={formData.education_degree_diploma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group
                    controlId="formEducationYearOfGraduation"
                    className="mb-3"
                  >
                    <Form.Label>Year of Graduation</Form.Label>
                    <Form.Control
                      type="number"
                      name="education_year_of_graduation"
                      value={formData.education_year_of_graduation}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* *****************************employment*************************************************************** */}

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Previous Employment/Internship Information
                {/* <Button variant="primary" onClick={addEmploymentEntry}>
  + Add Employment/Internship
</Button> */}
              </h2>

              {formData.employmentHistory.map((entry, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Row>
                    <Col md={4}>
                      <Form.Group
                        controlId={`formPreviousCompany${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Previous Company</Form.Label>
                        <Form.Control
                          type="text"
                          name="previous_company"
                          value={entry.previous_company}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId={`formPreviousPosition${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Previous Position</Form.Label>
                        <Form.Control
                          type="text"
                          name="previous_position"
                          value={entry.previous_position}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId={`formPreviousDatesOfEmployment${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Dates of Employment</Form.Label>
                        <Form.Control
                          type="date"
                          name="previous_dates_of_employment"
                          value={entry.previous_dates_of_employment}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Group
                        controlId={`formCompanyAddress${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="company_address"
                          value={entry.company_address}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        controlId={`formSupervisorName${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Supervisor Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="supervisor_name"
                          value={entry.supervisor_name}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        controlId={`formCompanyPhoneNo${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Company Phone No</Form.Label>
                        <Form.Control
                          type="text"
                          name="company_phone_no"
                          value={entry.company_phone_no}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={8}>
                      <Form.Group
                        controlId={`formPreviousReasonForLeaving${index}`}
                        className="mb-3"
                      >
                        <Form.Label>Reason for Leaving</Form.Label>
                        <Form.Control
                          type="text"
                          name="previous_reason_for_leaving"
                          value={entry.previous_reason_for_leaving}
                          onChange={(e) => handleEmploymentChange(index, e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4} className="d-flex align-items-center">
                      {formData.employmentHistory.length > 1 && (
                        <Button
                          variant="danger"
                          onClick={() => removeEmploymentEntry(index)}
                          className="me-2"
                        >
                          Remove
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              <Button variant="primary" onClick={addEmploymentEntry}>
                + Add Employment/Internship
              </Button>

              {name === "paid" ? (
                <>
                  <h2
                    style={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      fontSize: "1.3rem",
                    }}
                  >
                    Banking Information
                  </h2>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId="formBankName" className="mb-3">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="bank_name"
                          value={formData.bank_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId="formAccountNumber"
                        className="mb-3"
                      >
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="account_number"
                          value={formData.account_number}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId="formAccountHolderName"
                        className="mb-3"
                      >
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="account_holder_name"
                          value={formData.account_holder_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId="formBankBranch" className="mb-3">
                        <Form.Label>Bank Branch</Form.Label>
                        <Form.Control
                          type="text"
                          name="bank_branch"
                          value={formData.bank_branch}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Medical Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formBloodGroup" className="mb-3">
                    <Form.Label>Blood Group</Form.Label>
                    <Form.Control
                      type="text"
                      name="blood_group"
                      value={formData.blood_group}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group
                    controlId="formMedicalConditions"
                    className="mb-3"
                  >
                    <Form.Label>Medical Conditions</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3} // You can adjust this value as needed
                      name="medical_conditions"
                      value={formData.medical_conditions}
                      onChange={handleChange}
                      className="resize-y" // Makes the textarea resizable vertically
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formMedicalInsurance" className="mb-3">
                    <Form.Label>Medical Insurance</Form.Label>
                    <Form.Select
                      name="medical_insurance"
                      value={formData.medical_insurance}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Reference Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formReferenceName" className="mb-3">
                    <Form.Label>Reference Name 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_name"
                      value={formData.reference_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formReferenceRelationship"
                    className="mb-3"
                  >
                    <Form.Label>Reference Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_relationship"
                      value={formData.reference_relationship}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formReferenceTelephone"
                    className="mb-3"
                  >
                    <Form.Label>Reference Telephone</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_telephone"
                      value={formData.reference_telephone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formReferenceEmail" className="mb-3">
                    <Form.Label>Reference Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="reference_email"
                      value={formData.reference_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* New Reference Fields */}
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formReferenceName2" className="mb-3">
                    <Form.Label>Reference Name 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_name2"
                      value={formData.reference_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formReferenceRelationship2"
                    className="mb-3"
                  >
                    <Form.Label>Reference Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_relationship2"
                      value={formData.reference_relationship}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formReferenceTelephone2"
                    className="mb-3"
                  >
                    <Form.Label>Reference Telephone</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_telephone2"
                      value={formData.reference_telephone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formReferenceEmail2" className="mb-3">
                    <Form.Label>Reference Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="reference_email2"
                      value={formData.reference_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Card.Text
                style={{
                  marginTop: "35px",
                  marginBottom: "34px",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                I hereby confirm that all the above information is accurate to
                the best of my knowledge.
              </Card.Text>

              <Row>
                <Form.Group controlId="formDeclarationDate" className="mb-4">
                  <Form.Label>Declaration Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="declaration_date"
                    value={formData.declaration_date}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group
                    controlId="formDeclarationSignature"
                    className="mb-3"
                  >
                    <Form.Label>Declaration Signature</Form.Label>
                    <SignatureBox onSave={handleSignatureSave} />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    controlId="formDeclarationSignature"
                    className="mb-3"
                  >
                    <Form.Label>Saved Signature</Form.Label>
                    <div>
                      {signature ? (
                        <>
                          <img src={signature} alt="Signature" />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </Button>

                <Button variant="danger">{"Cancel"}</Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        {/* <Footer /> */}
      </Container>
    </>
  );
};

export default JoiningForm;
